import React from 'react';
import '../../Style/App.css';
import Nav from './NavigationBar';

function Abszolvalt() {
    return (
        <div>
            <Nav />
            <br />
            <span className="info-titleuser">Under Development</span>
        </div>
    );
}

export default Abszolvalt;
